import React, { useContext, createContext, useState } from "react";
import axios from 'axios';

// Log messages
function debugLog(message,obj = null) {
  if(process.env.REACT_APP_LOG === "true") { 
    if(obj) {
      console.log(message + JSON.stringify(obj, null, 4))
    } else {
      console.log(message);
    }
  }
}

// Error handler
function handleError(error) {
  debugLog("error: " + error.stack);
}

const platformAuth = {
  url: process.env.REACT_APP_BASE_PATH,
  clientId: process.env.REACT_APP_CLIENT_ID,
  isAuthenticated: false,

  // Sign in to the platform,
  // cb: (response,success)
  signin(user, password, cb) {
    axios.post(
      platformAuth.url + '/signin',
      {
        "username": user,
        "password": password,
        "clientId": platformAuth.clientId
      }
    )
      .then((response) => {
        if (response.status < 400) {
          platformAuth.isAuthenticated = true;
        }
        cb(response.data, response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error, false);
      });
  },

  // Sign up to the platform,
  // cb: (response,success)
  signup(user, password, cb) {

    var attrs = []
    if(process.env.REACT_APP_SIGNUP_BIRTHDATE != undefined) { 
      attrs = [
        {
          "name":"birthdate",
          "value":process.env.REACT_APP_SIGNUP_BIRTHDATE,
        }
      ]
    }

    axios.post(
      platformAuth.url + '/signup',
      {
        "username":user,
        "password":password,
        "clientId":platformAuth.clientId,
        "attributes":attrs
      }
    )
      .then((response) => {
        debugLog("response: ",response.data);
        if (response.status < 400) {
          platformAuth.isAuthenticated = true;
        }
        cb(response.data, response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error, false);
      });
  },

  // Sign in to the platform,
  // cb: (response,success)
  requestResetPassword(user, cb) {
    axios.post(
      platformAuth.url + '/resetpassword',
      {
        "username": user,
        "clientId": platformAuth.clientId
      }
    )
      .then((response) => {
        //alert(JSON.stringify(response, null, 4));        
        cb(response.data, response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error, false);
      });
  },

  // Reset password with code
  // cb: (response,success)
  resetPassword(user, code, password, cb) {
    axios.post(
      platformAuth.url + '/changepassword',
      {
        "username": user,
        "password": password,
        "code": code,        
        "clientId": platformAuth.clientId
      }
    )
      .then((response) => {
        cb(response.data, response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error, false);
      });
  },

  // Refresh access tokens
  // cb: (response,success)
  refreshToken(idToken, refreshToken, cb) {
    axios.post(
      platformAuth.url + '/refresh',
      {
        "refreshToken": refreshToken,        
        "clientId": platformAuth.clientId
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response: ",response.data);
        cb(response.data, response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error, false);
      });
  },

  // Sign out
  signout(accessToken, idToken, cb) {
    axios.post(
      platformAuth.url + '/signout',
      {
        "accessToken": accessToken,        
        "clientId": platformAuth.clientId
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog(`response: ${response}`);
        platformAuth.isAuthenticated = false;
        cb(response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(false);
      });
  },

  // GetProfile
  getProfile(userId, idToken, cb) {
    debugLog(`getProfile: ${userId}`);
    axios.get(
      platformAuth.url + '/users/' + userId + '/profile',
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error,false);
      });
  },

  // GetOffers
  getOffers(userId, idToken, cb) {
    debugLog(`getOffers: ${userId}`);
    axios.get(
      platformAuth.url + '/purchases/offer',
      {
        params: { id: 'prod_KTU06xT0isjlvU' },
        headers: {
          'Authorization': 'Bearer ' + idToken,
        } 
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error,false);
      });
  },

  // update profile
  putProfile(userId, idToken, profile, cb) {
    debugLog(`putProfile: ${userId}`);
    axios.put(
      platformAuth.url + '/users/' + userId + '/profile',
      {
        "userId": userId,
        "userEmail": profile.userEmail,
        "userName": profile.userName,
        "locale": profile.locale,
        "campaignId": profile.campaign,
        "createdDate": profile.createdDate
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        handleError(error);
        cb(error,false);
      });
  },

  // purchange subscription
  purchaseSubscription(userId, idToken, productId, priceId, cb) {
    debugLog(`purchaseSubscription: ${userId}, ${productId}, ${priceId}`);
    axios.post(
      platformAuth.url + '/users/' + userId + '/purchases',
      {
        "userId": userId,
        "productId": productId,
        "priceId": priceId,
        "source": "stripe",
        "date": new Date().toISOString(),
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        console.log( error.response )
        handleError(error);
        cb(error,false);
      });
  },

  // restore subscription
  restoreSubscription(userId, idToken, cb) {
    debugLog(`restoreSubscription: ${userId}`);
    axios.post(
      platformAuth.url + '/users/' + userId + '/subscriptions/restore',
      {
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        console.log( error.response )
        handleError(error);
        cb(error,false);
      });
  },

  // redeem code
  redeemCode(userId, idToken, code, cb) {
    debugLog(`redeemCode: ${userId}`);
    axios.post(
      platformAuth.url + '/users/' + userId + '/purchases',
      {
        "userId": userId,
        date: new Date().toISOString(),
        purchaseId: code,
        source: "promocode",
      },
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        console.log( error.response )
        handleError(error);
        cb(error,false);
      });
  },

  // cancel subscription
  cancelSubscription(userId, idToken, productCode, cb) {
    debugLog(`cancelSubscription: ${userId}, ${productCode}`);
    axios.delete(
      platformAuth.url + '/users/' + userId + '/subscriptions/' + productCode,
      {
        headers: {
          'Authorization': 'Bearer ' + idToken,
        }
      }
    )
      .then((response) => {
        debugLog("response",response.data);
        cb(response.data,response.status < 400);
      })
      .catch(error => {
        console.log( error.response )
        handleError(error);
        cb(error,false);
      });
  }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  
  /* const _jwt = (response) => {
    var base64Url = response.IdToken.split('.')[1];
    var decodedValue = JSON.parse(window.atob(base64Url));
    return decodedValue;
  }

  const _arrayCompare = (source, match) => {
    if (Array.isArray(source)) {
      return source.indexOf(match) != -1;
    } else if (source === match) {
      return true;
    }
    return false;
  }  */

  const signin = (user, password, cb) => {
    return platformAuth.signin(user, password, (response, success) => {
      if (success === true) {
        setLocalStorage(response.refreshToken,response.IdToken,response.userId)
        setUser(response);
      }
      cb(response,success);
    });
  };

  const signup = (user, password, campaign, locale, createdDate, cb) => {
    return platformAuth.signup(user, password, (response, success) => {
      if (success === true) {
        setLocalStorage(response.refreshToken,response.IdToken,response.userId)
        setUser(response);

        // create an 'empty' profile
        platformAuth.putProfile(
          response.userId,
          response.IdToken,
          {
            "userEmail": user,
            "userName": user,
            "locale": locale,
            "campaignId": campaign,
            "createdDate": createdDate
          },
          (response1, success1) => {
            cb(response,success);
          }
        );
      } else {
        cb(response,success);
      }
    });
  };

  const signout = cb => {
    if(!user) {
      cb();
      return;
    }
    return platformAuth.signout(accessToken,idToken,(success) => {
      clearUser();
      cb();
    });
  };

  // remove the current user and any stored credentials that could be 
  // used to refresh the access tokens for this user
  const clearUser = () => {
    debugLog("clearing user information")
    clearLocalStorage();
    setUser(null);
  }

  const requestResetPassword = (user, cb) => {
    return platformAuth.requestResetPassword(user, (response, success) => {
      cb(response, success);
    });
  };

  const resetPassword = (user, code, password, cb) => {
    return platformAuth.resetPassword(user, code, password, (response, success) => {
      cb(response, success);
    });
  };

  const getProfile = (cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.getProfile(userId, idToken, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // get the subscription offers for this user
  const getOffers = (cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.getOffers(userId, idToken, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // get the subscription offers for this user
  const updateProfile = (profile,cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.putProfile(userId, idToken, profile, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // restore subscriptions
  const restoreSubscription = (cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.restoreSubscription(userId, idToken, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // cancel subscription
  const cancelSubscription = (productCode,cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.cancelSubscription(userId, idToken, productCode, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // purchase a subscription offer
  const purchaseSubscription = (productId,priceId,cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.purchaseSubscription(userId, idToken, productId, priceId, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  // restore subscriptions
  const redeemCode = (code,cb) => {
    refreshTokens((signedIn,idToken,userId) => {
      if(signedIn) {
        platformAuth.redeemCode(userId, idToken, code, (response, success) => {
          cb(response, success);
        });
      } else {
        cb(null, false);
      }
    })
    
  };

  const idToken = () => {
    return user.IdToken;
  }

  const accessToken = () => {
    return user.accessToken;
  }

  const userId = () => {
    return user.userId;
  }

  const tryUserId = () => {
    if(!user) return ""
    return user.userId;
  }

  const jwt = (_idToken) => {
    var base64Url = _idToken.split('.')[1];
    var decodedValue = JSON.parse(window.atob(base64Url));
    return decodedValue;
  }

  // check to see if the user has state, and if not is there 
  // a JWT in localStorage that hasn't expired. This doesn't
  // guarantee that a refresh of the token will be successful
  // but does suggest the user is likely to expect they are
  // logged in
  const isPotentiallySignedIn = () => {
    try {
      if(user) return true
      const _idToken = localStorage.getItem('idToken')
      if(!_idToken) return false
      const _jwt = jwt(_idToken)
      if(!_jwt) return false
      return true
    } 
    catch (error) {
      handleError(error);
      return false;
    }
  }

  // we check the expiry in the JWT giving a grace time of
  // 60 seconds to ensure that requests after this check
  // are still valid in the time and allow for a small amount
  // of clock drift. An invalid cert will also return as expired
  const hasExpired = (_jwt) => {
    try {
      let expiresIn = _jwt.exp - (Date.now() / 1000);
      debugLog(`JWT expires in ${expiresIn} seconds`)
      return expiresIn < 60;
    } 
    catch (error) {
      handleError(error);
      return true;
    }
  }

  const refreshTokens = (cb) => {
    var _refreshToken
    var _idToken
    var _userId

    if(!user) {
      debugLog("No current user, checking localStorage...");

      // there is no active user in context, so we look for a refreshToken
      // and then get new credentials for the current user
      _refreshToken = localStorage.getItem('refreshToken')
      _idToken = localStorage.getItem('idToken')
      _userId = localStorage.getItem('userId')
    }

    // we have a user, check the JWT has not expired
    else {
      if(!hasExpired(jwt(user.IdToken))) {
        debugLog("JWT valid");
        cb(true,user.IdToken,user.userId);
        return;
      }

      debugLog("JWT expired, refreshing...");
      _refreshToken = user.refreshToken
      _idToken = user.accessToken
      _userId = user.userId
    }

    // check we have access and refresh tokens to refresh
    if(!_refreshToken || !_idToken || !_userId ) {
      debugLog("No tokens found to refresh from");
      cb(false,null,null);
      return;
    }

    // refresh the tokens
    debugLog("Refreshing tokens...");
    return platformAuth.refreshToken(_idToken, _refreshToken, (response, success) => {
      debugLog(`refresh tokens ${success}`);
      if(success) {
        try {
          setUser(response);
          setLocalStorage(response.refreshToken,response.IdToken,response.userId)
          cb(true,response.IdToken,response.userId);
        }
        catch(error) {
          handleError(error)
          clearUser();
          cb(false,null,null);
        }
      } else {
        debugLog("Failed to refresh tokens")
        clearUser();
        cb(false,null,null);
      }
    });
  }

  // determine if the user is logged in, this will include use a local
  // state for the user, and then checking the expiry date on the access
  // token. If there is no local state for the user, we look for cookies
  // and then refresh the access tokens from there
  const isLoggedIn = (cb) => {
    debugLog("determine signed in status")
    refreshTokens(cb);
  }

  // store session variables
  const setLocalStorage = (refreshToken,idToken,userId) => {
    localStorage.setItem('refreshToken',refreshToken)
    localStorage.setItem('idToken',idToken)
    localStorage.setItem('userId',userId)
  }

  // clear session variables
  const clearLocalStorage = () => {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('idToken')
    localStorage.removeItem('userId')
  }

  return {
    user,
    signin,
    signout,
    requestResetPassword,
    resetPassword,
    refreshTokens,
    idToken,
    jwt,
    hasExpired,
    userId,
    accessToken,
    isLoggedIn,
    getProfile,
    signup,
    getOffers,
    updateProfile,
    purchaseSubscription,
    isPotentiallySignedIn,
    setLocalStorage,
    clearLocalStorage,
    tryUserId,
    restoreSubscription,
    cancelSubscription,
    redeemCode
  };
}