import React, { useState } from "react";
import {
  useHistory
} from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from "./Auth.js";
import * as Constants from './Constants.js';

export function PasswordResetPage(props) {

  let auth = useAuth();

  let history = useHistory();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [codeEntry, setCodeEntry] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [code, setCode] = useState("");
  const [emailEntry, setEmailEntry] = useState(true);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if(!code) {
      requestResetPassword();
    } else {
      resetPassword();
    }
  }

  let requestResetPassword = () => {
    if(name.length === 0) {
      setErrorMessage("Invalid email")
      return;
    } 

    setErrorMessage("")
    setBusy(true)
    auth.requestResetPassword(name, (response,success) => {
      setBusy(false)
      if (success) {
        setEmailEntry(false)
        setCodeEntry(true)
        setInfoMessage("A reset code has been sent to " + response.destination)
      } else {
        setErrorMessage("Invalid email")
      }
    });
  };

  let resetPassword = () => {
    if(code.length === 0 || password.length === 0) {
      setErrorMessage("Invalid code or password")
      return;
    } 

    setErrorMessage("")
    setBusy(true)
    auth.resetPassword(name, code, password, (response,success) => {
      setBusy(false)
      if (success) {
        setCodeEntry(false)
        setInfoMessage("Password has been successfully updated")
      } else {
        setErrorMessage("Failed to reset password")
      }
    });
  };

  let startCodeEntry = () => {
    setEmailEntry(false)
    setCodeEntry(true)
    setErrorMessage("")
    setInfoMessage("")
  };

  let startLogin = () => {
    setEmailEntry(true)
    setCodeEntry(false)
    setErrorMessage("")
    setInfoMessage("")
    history.replace(Constants.ROUTES.Login);
  };

  return (
    <Container maxWidth="sm">
      <Box style={{ textAlign: "center" }}>
        <img 
          style={{ width: "200px", padding: '20px 0px 20px 0px' }}
          src={process.env.REACT_APP_CHROME + '/logo.png'}
          alt={process.env.REACT_APP_CHROME}
        />
        

        <form onSubmit={handleSubmit}>
          <Typography variant="body1">
            Forgotten your password?
          </Typography>
          <Typography variant="body1">
            Enter your email address and we will send you instructions
          </Typography>
          <Container style={{ padding: '20px 0px 0px 0px' }}/>
          { errorMessage && <Alert severity="error">{errorMessage}</Alert> }
          { infoMessage && <Alert severity="info">{infoMessage}</Alert> }
          { emailEntry && 
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              variant="filled"
              autoComplete="email"
              autoFocus
              disabled={busy ? "disabled" : ""}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          }
          { codeEntry && 
            <TextField
              margin="normal"
              fullWidth
              name="scode"
              autoComplete="new-code"
              label="Code"
              id="scode"
              variant="filled"
              disabled={busy ? "disabled" : ""}
              onChange={e => setCode(e.target.value)}
            /> 
          }
          { codeEntry && 
            <TextField
              margin="normal"
              fullWidth
              name="spassword"
              label="New Password"
              type="password"
              id="spassword"
              autoComplete="new-password"
              variant="filled"
              disabled={busy ? "disabled" : ""}
              onChange={e => setPassword(e.target.value)}
            /> 
          }
          { (codeEntry || emailEntry) &&
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              disabled={busy ? "disabled" : ""}
            >
              Reset Password
            </Button>
          }
          {busy && <LinearProgress/>}
          <Container style={{ padding: '20px 0px 0px 0px' }}/>
          <Button 
            variant="text"
            onClick={() => {
              startLogin();
            }}
          >
            Return to Login
          </Button>
          |
          <Button 
            variant="text"
            onClick={() => {
              startCodeEntry();
            }}
          >
            I have a reset code
          </Button>
        </form>
      </Box>
    </Container>
  );
}