import React, { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useAuth } from "./Auth.js";
import * as Constants from './Constants.js';
import {hasSubsciprionExpired, getSubsciprionMessage, getSubscriptionEndDate, AvailableSubscriptions, SubmitPayment} from "./Subscribe.js";
import * as Products from './Products.js';

const Item = (props) => {
  /*var { title, content, ...rest } = props; */
  var { ...rest } = props;
  return (
    <Grid style={{ display: "flex", flexDirection: "column" }} item {...rest}>
      <Paper style={{ height: "100%", padding: '20px 20px 20px 20px' }}>
        {props.children}
      </Paper>
    </Grid>
  );
};

export default function LoginPage(props) {

  let auth = useAuth();
  const authRef = useRef({});
  authRef.current = auth;

  let registrationMode = props.mode === "resgistration"

  let history = useHistory();
  let location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [message, setMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [signUpStep, setSignUpStep] = useState(null);
  const [purchaseProduct, setPurchaseProduct] = useState(null);
  const [purchaseSecret, setPurchaseSecret] = useState(null);
  const [purchaseStatus, setPurchaseStatus] = useState(null);
  const [profile, setProfile] = useState(null);
  
  const checkedLoggedIn = () => {
    console.log("checking signed in status...")
    authRef.current.isLoggedIn((loggedIn) => {
      console.log(`signed in status ${loggedIn}`)
      setLoggedIn(loggedIn)
    });
    // is this a redirect from a successful purchase ?
    // http://localhost:3000/signup?setup_intent=seti_1KlvtGGJ6P44aoOIkKCuR15h&setup_intent_client_secret=seti_1KlvtGGJ6P44aoOIkKCuR15h_secret_LSrcW4aezQnlVciROUushPOjN4QVgc7&redirect_status=succeeded
    try {
      let params = (new URL(document.location)).searchParams;
      if(params.get('redirect_status') != null) {
        setSignUpStep(4)
      }
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkedLoggedIn();
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if(registrationMode) {
      register();
    } else {
      login();
    }
  }

  let { from } = location.state || { from: { pathname: Constants.ROUTES.Home } };
  let successfulSignUpOrSignIn = () => {
    if(signUpStep && signUpStep == 1) {
      setLoggedIn(true)
      completeSubscription(purchaseProduct)
    } else {
      history.replace(from);
    }
  }


  let login = () => {
    setMessage("")
    setBusy(true)
    auth.signin(email, password, (response,success) => {
      setBusy(false)
      if (success) {
        successfulSignUpOrSignIn()
      } else {
        setMessage("Invalid email or password.")
      }
    });
  };

  let register = () => {
    setMessage("")
    setBusy(true)
    var campaign = ""
    var locale = "";
    var createdDate = "";
    try {
      locale = navigator.language;
      createdDate = new Date().toISOString();
      let params = (new URL(document.location)).searchParams;
      campaign = params.get('campaignId'); 
    } catch(error) {
      console.log(error)
    }
    auth.signup(email, password, campaign, locale, createdDate, (response,success) => {
      setBusy(false)
      if (success) {
        successfulSignUpOrSignIn()
      } else {
        if(response == "UsernameExists") {
          setMessage("A user with that email already exists")
        }
        else if(response == "InvalidPassword") {
          setMessage("The password specified does not meet requirements")
        } else {
          setMessage("Invalid email or password.")
        }
      }
    });
  };

  let startResetPassword = () => {
    history.replace(Constants.ROUTES.ResetPassword);
  };

  let startRegistration = () => {
    setMessage("")
    setEmail("")
    setPassword("")
    history.replace(Constants.ROUTES.Register);
  };

  let startLogin = () => {
    setMessage("")
    setEmail("")
    setPassword("")
    history.replace(Constants.ROUTES.Login);
  };

  let showAccountSettings = () => {
    history.replace(Constants.ROUTES.Home);
  };

  const purchaseSubscription = (product) => {
    setPurchaseProduct(product)
    auth.isLoggedIn((loggedIn) => {
      setLoggedIn(loggedIn)
      if(loggedIn) {
        auth.getProfile((profile,success) => {
          setProfile(profile)
          if(success) {
            if(hasSubsciprionExpired(profile)) {
              completeSubscription(product)
            } else {
              setSignUpStep(3)
            }
          } else {
            setMessage("Unable to load profile")
            backOneStep()
          }
          
        })
      } else {
        setSignUpStep(1)
      }
    });
  }

  const completeSubscription = (product) => {
    setSignUpStep(2)
    setMessage("")
    auth.purchaseSubscription(product.productId,product.priceId,(response,success) => {
      if(success) {
        setPurchaseSecret(response.serverVerificationData)
        setPurchaseStatus(response.status)
      } else {
        setMessage(`Failed to purchase subscription. Please contact customer services`)
      }
    });
    
  }

  const backOneStep = () => {
    setSignUpStep(null)
  }

  return (
    <Container>
      <Box style={{ textAlign: "center" }}>
        <img 
          style={{ width: "200px", padding: '20px 0px 20px 0px' }}
          src={process.env.REACT_APP_CHROME + '/logo.png'}
          alt={process.env.REACT_APP_TITLE}
        />
        {loggedIn && !signUpStep && 
          <Alert severity="info">You are already logged in</Alert>
        }
        {(registrationMode && !signUpStep) &&
          <Container>
            <AvailableSubscriptions
              purchaseSubscription={purchaseSubscription}
            />
            {(registrationMode && !loggedIn ) &&       
              <Container style={{ padding: '20px 0px 0px 0px' }}>
                <Button 
                  variant="text"
                  onClick={() => {
                    startLogin();
                  }}
                >
                  I have an account
                </Button>  
              </Container>
            }
            <Container style={{ padding: '20px 0px 0px 0px' }}></Container>
          </Container>
        }
        <Container maxWidth="sm">
          <Box style={{ textAlign: "center" }}>
            {signUpStep && signUpStep != 4 && 
              <Box>
                <Typography gutterBottom variant="body" component="div">
                signUpStep {signUpStep}
                </Typography>
                <Button 
                  variant="text"
                  onClick={() => {
                    backOneStep();
                  }}
                >
                  Back
                </Button>
              </Box>
            }
            {(registrationMode && signUpStep == 1) && 
              <Box>
                <Typography gutterBottom variant="body" component="div">
                  Create new account
                </Typography>
              </Box>
            }
            {signUpStep == 2 && 
              <SubmitPayment
                purchaseSecret={purchaseSecret}
                purchaseStatus={purchaseStatus}
                product={purchaseProduct}
                onError={(err) => {
                  setMessage(err);
                }}
              />
            }

            {signUpStep == 3 && 
              <Box>
                { getSubsciprionMessage(profile) + " " + getSubscriptionEndDate(profile) }
              </Box>
            }

            {signUpStep == 4 && 
              <Box>
                <Alert severity="info">Your Subscription was successful</Alert>
              </Box>
            }
            
            {((registrationMode && signUpStep == 1) || (!registrationMode && (!signUpStep || signUpStep == 1))) && 
              <form onSubmit={handleSubmit} autocomplete="off">          
                {message && <Alert severity="error">{message}</Alert>}
                <TextField
                  margin="normal"
                  fullWidth
                  id={registrationMode ? "new-email" : "email"}
                  label="Email Address"
                  name={registrationMode ? "new-email" : "email"}
                  variant="filled"
                  autoComplete={registrationMode ? "new-email" : "email"}
                  autoFocus
                  disabled={busy}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete={registrationMode ? "new-password" : "current-password"}
                  variant="filled"
                  disabled={busy}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                /> 
                { registrationMode &&
                  <Typography gutterBottom variant="body2" component="div" style={{ padding: '0px 0px 20px 0px' }}>
                    Minimum of 6 characters required. For additional security, we recommend including numbers, uppercase letters, and special characters.
                  </Typography>
                }
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={busy}
                >
                  {registrationMode ? "Sign Up" : "Sign In"
                  }
                </Button>
                {busy && <LinearProgress/>}
                <Container style={{ padding: '20px 0px 0px 0px' }}/>
                {!registrationMode && 
                  <Button 
                    variant="text"
                    onClick={() => {
                      startResetPassword();
                    }}
                  >
                    Reset Password
                  </Button>  
                }
                {!registrationMode && 
                  <Button 
                    variant="text"
                    onClick={() => {
                      startRegistration();
                    }}
                  >
                    I need an account
                  </Button>  
                }
                {(registrationMode) &&
                  <Button 
                    variant="text"
                    onClick={() => {
                      startLogin();
                    }}
                  >
                    I have an account
                  </Button>  
                }
              
              </form>
            }
            {loggedIn && 
              <Button 
                variant="text"
                onClick={() => {
                  showAccountSettings();
                }}
              >
                Account Settings
              </Button>
            }
          </Box>
        </Container>
      </Box>
    </Container>
  );
}