import React, { useEffect, useState } from "react";
import {
  useHistory,
} from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';

import moment from 'moment'
import {isNewUser, getSubsciprionMessage, getSubscriptionEndDate, hasSubsciprionExpired, AvailableSubscriptions, SubmitPayment} from "./Subscribe.js";

import { useAuth } from "./Auth.js";
import * as Constants from './Constants.js';
import * as Products from './Products.js';

const Item = (props) => {
  /*var { title, content, ...rest } = props; */
  var { ...rest } = props;
  return (
    <Grid style={{ display: "flex", flexDirection: "column" }} item {...rest}>
      <Paper style={{ height: "100%", padding: '20px 20px 20px 20px' }}>
        {props.children}
      </Paper>
    </Grid>
  );
};

const Field = (props) => {
  var { title, ...rest } = props;
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography gutterBottom variant="body" component="div">
          {title}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography gutterBottom variant="body" component="div">
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
}

const SubscriptionRow = (props) => {
  var { sub, onCancelClick, ...rest } = props;
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography gutterBottom variant="body" component="div">
          {sub.productId}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="body" component="div">
          {sub.source}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography gutterBottom variant="body" component="div">
          {sub.endDate}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography gutterBottom variant="body" component="div">
          {sub.subscriptionStatus}
        </Typography>
      </Grid>
      <Grid item xs>
        {sub.subscriptionStatus == "active" && sub.source.toLowerCase() == "stripe" && 
          <Button
            variant="outlined"
            onClick={() => {
              onCancelClick()
            }}
          >
            Cancel
          </Button>
        }
      </Grid>
    </Grid>
  );
}

const CompositeDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function AccountSettings() {
  
  let auth = useAuth();
  let history = useHistory();

  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [offers, setOffers] = useState(null);
  const [editMode, setEditMdoe] = useState(false);
  const [busy, setBusy] = useState(false);
  const [newProfile, setNewProfile] = useState(null);
  const [purchaseSecret, setPurchaseSecret] = useState(null);
  const [purchaseStatus, setPurchaseStatus] = useState(null);
  const [purchaseOffer, setPurchaseOffer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [cancelProductCode, setCancelProductCode] = useState("");
  const [popupMessaage, setPopupMessage] = useState("");
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [redemtionCode, setRedemtionCode] = useState(null);
  const [redemtionCodeError, setRedemtionCodeError] = useState("");

  let signOut = () => {
    auth.signout(() => {
      history.replace(Constants.ROUTES.Login);
    });
  };

  const loadProfile = () => {
    setErrorMessage("")
    auth.getProfile((profile,success) => {
      console.log(`profile status ${success}`)
      if(success) {
        setProfile(profile)
        loadOffers();
      } else {        
        setProfile({
          userName: "",
          userEmail:  "",
          userId: auth.tryUserId()
        })
        setErrorMessage("Unable to access account settings")
        setLoading(false)
      }
    });
  }

  const loadOffers = () => {
    setOffers(Products.list())
    setLoading(false)
  }

  const editProfile = (enable) => {
    setEditMdoe(enable)
    setNewProfile({
      userName: profile.userName,
      userEmail: profile.userEmail,
    })
  }

  const updateProfile = (userName,userEmail) => {
    setNewProfile({
      userName: userName != null ? userName : newProfile.userName,
      userEmail:  userEmail != null ? userEmail : newProfile.userEmail,
    })
  }

  const validateFields = () => {
    if(!newProfile.userName || !newProfile.userEmail) {
      return false
    }
    if(newProfile.userName.trim().length < 2 || newProfile.userEmail.trim().length < 2) {
      return false
    }
    const regex = new RegExp('[^@]+@[^@]+\.[^@]+');
    if(!regex.test(newProfile.userEmail)) {
      return false
    }
    return true;
  }

  const saveChanges = () => {
    setBusy(true)
    auth.updateProfile(newProfile,(_,success) => {
      console.log(`updateProfile status ${success}`)
      setBusy(false)
      if(success) {
        setEditMdoe(false)
        setProfile({
          userName: newProfile.userName,
          userEmail:  newProfile.userEmail,
          userId: auth.userId(),
        })
        setErrorMessage("")
      } else {
        setErrorMessage("Unable to save changes to your profile")
      }
    });
  }

  const restoreSubscription = () => {
    setLoading(true)
    auth.restoreSubscription((_,success) => {
      console.log(`restoreSubscription status ${success}`)
      loadProfile();
    });
  }

  const cancelSubscription = (productCode) => {
    setLoading(true)
    auth.cancelSubscription(productCode,(_,success) => {
      console.log(`cancelSubscription status ${success}`)
      setCancelProductCode("")
      if(success) { 
        loadProfile();
      } else {
        setLoading(false)
        setErrorMessage(`Failed to cancel subscription. Please contact customer services`)
      }
    });
  }

  const promptToCancel = (sub) => {
    if(sub.source == "Stripe") {
      setCancelProductCode(sub.productId)
    } else {
      setPopupMessage("Cancel through the Apple AppStore or Goole Play Store")
    }
  }

  const redeemCode = () => {
    setRedemtionCodeError("")
    if(!RegExp("^[0-9]+-[0-9]+$").test(redemtionCode)) {
      setRedemtionCodeError("Invalud redemption code")
    } else {
      setRedemtionCodeError("")
      auth.redeemCode(redemtionCode,(_,success) => {
        console.log(`redeemcode status ${success}`)
        if(success) {
          setRedemtionCode(null);
          setShowEnterCode(false);
          loadProfile();
        } else {
          setRedemtionCodeError("Failed to redeem code")
        }
      });
    }
  }

  const purchaseSubscription = (offer) => {
    setErrorMessage("")
    setBusy(true)
    setPurchaseOffer(offer)
    auth.purchaseSubscription(offer.productId,offer.priceId,(response,success) => {
      console.log(`purchaseSubscription status ${success}`)
      setBusy(false)
      if(success) {
        console.log(`serverVerificationData: ${response.serverVerificationData}`)
        console.log(`Ready: ${ready}`)
        setPurchaseSecret(response.serverVerificationData)
        setPurchaseStatus(response.status)
      } else {
        setPurchaseOffer(null)
        setErrorMessage(`Failed to purchase subscription. Please contact customer services`)
      }
    });
  }

  const handlePurchaseCancel = () => {
    setBusy(false)
    setPurchaseSecret(null)
    setPurchaseOffer(null)
    setReady(false)
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="secondary">
          <Toolbar>            
            <Box
              component="img"
              sx={{
                width: 100,   
              }}
              alt={process.env.REACT_APP_CHROME}
              src={process.env.REACT_APP_CHROME + '/settings-logo.png'}
            />
            <Box
              sx={{ flexGrow: 1 }}
            />
            <Button 
              color="inherit"
              onClick={() => {
                signOut();
              }}
            >
              Sign out
            </Button>        
          </Toolbar>
        </AppBar>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {profile &&
          <Grid container spacing={2} rowSpacing={2} justify="space-between" alignItems="stretch" style={{ padding: '20px 20px 20px 20px' }}>
            <Item xl={6} lg={6} md={6} xs={12}>
              {editMode &&
                <Field title="Name:">
                  <TextField
                    fullWidth
                    hiddenLabel
                    size="small"
                    id="name"
                    name="name"
                    variant="filled"
                    autoFocus
                    disabled={busy}
                    value={newProfile.userName}
                    onChange={e => updateProfile(e.target.value,null)}
                  />
                </Field>   
              }
              {!editMode &&
                <Field title="Name:">
                  {profile.userName}
                </Field> 
              }
              {editMode &&
                <Field title="Email:">
                <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  id="email"
                  name="email"
                  variant="filled"
                  disabled={busy}
                  value={newProfile.userEmail}
                  onChange={e => updateProfile(null,e.target.value)}
                />
              </Field>   
              }
              {!editMode &&
                <Field title="Email:">
                  {profile.userEmail}
                </Field>            
              }
              <Field title="User ID:">
                {profile.userId}
              </Field>

              {editMode && 
                <Grid container spacing={2} justifyContent="flex-end" style={{ padding: '20px 0px 0px 0px' }}>
                  <Grid item>
                    <Button 
                      variant="contained"
                      disabled={busy || !validateFields()}
                      onClick={() => {
                        saveChanges()
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button 
                      variant="outlined"
                      onClick={() => {
                        editProfile(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              }
              {!editMode && 
                <Grid container spacing={2} justifyContent="flex-end" style={{ padding: '20px 0px 0px 0px' }}>
                  <Grid item>
                    <Button 
                      variant="outlined"
                      onClick={() => {
                        editProfile(true)
                      }}
                    >
                      Change Details
                    </Button>
                  </Grid>
                </Grid>
              }
            </Item>
            {offers &&
              <Item xl={6} lg={6} md={6} xs={12}>
                {isNewUser(profile) && 
                  <Box>
                    <Typography variant="subtitle1" gutterBottom >
                      Train with {process.env.REACT_APP_TITLE}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom >
                      Start your subscription today
                    </Typography>                
                  </Box>
                }
                {!isNewUser(profile) && 
                  <Box>
                    <Field title={getSubsciprionMessage(profile)}>
                      {getSubscriptionEndDate(profile)}
                    </Field>
                    { Array.from(profile.subscriptions ?? [], (sub) => {
                      return(
                        <SubscriptionRow sub={sub} onCancelClick={() => promptToCancel(sub)}/>             
                      )
                    }) }
                  </Box>
                }                
                
                  <Grid container spacing={2} justifyContent="flex-end" style={{ padding: '20px 0px 0px 0px' }}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowProducts(true)
                        }}
                      >
                        Manage
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          restoreSubscription()
                        }}
                      >
                        Restore Subscriptions
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowEnterCode(true)
                        }}
                      >
                        Enter Code
                      </Button>
                    </Grid>
                  </Grid>                  
                
                
              </Item>
            }
          </Grid>
        }
      </Box>

      <Dialog open={cancelProductCode != ""} onClose={() => setCancelProductCode("")} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Cancel Subscription?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel your subscription? After the subscription renewal date you will no 
            longer be able to access content in the app
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelProductCode("")} autoFocus>No, don't make changes</Button>
          <Button onClick={() => cancelSubscription(cancelProductCode)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEnterCode} onClose={() => setShowEnterCode(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
          <CompositeDialogTitle onClose={() => setShowEnterCode(false)}>Subscribe to {process.env.REACT_APP_TITLE}</CompositeDialogTitle>
          <DialogContent>
            {redemtionCodeError && <Alert severity="error">{redemtionCodeError}</Alert>}
            <DialogContentText id="alert-dialog-description">
              Please enter your Base Pass membership code found in your FightLab Welcome email.
            </DialogContentText>
            <TextField
                  fullWidth
                  hiddenLabel
                  size="small"
                  id="code"
                  name="code"
                  variant="filled"
                  disabled={false}
                  value={redemtionCode}
                  onChange={e => setRedemtionCode(e.target.value)}
                />
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setShowEnterCode(false)} autoFocus>Cancel</Button>
          <Button onClick={() => redeemCode()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showProducts} onClose={() => setShowProducts(false)} aria-labelledby="form-dialog-title" maxWidth="m" >
          <CompositeDialogTitle onClose={() => setShowProducts(false)}>Subscribe to {process.env.REACT_APP_TITLE}</CompositeDialogTitle>
          <DialogContent>
            <AvailableSubscriptions
              purchaseSubscription={purchaseSubscription}
            />
            <Grid container spacing={2} justifyContent="center" style={{ padding: '20px 0px 0px 0px' }}>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowProducts(false)
                    restoreSubscription()
                  }}
                >
                  Restore Subscriptions
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
      </Dialog>
      
      <Dialog open={purchaseOffer != null} onClose={handlePurchaseCancel} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
          <CompositeDialogTitle onClose={handlePurchaseCancel}>Subscribe to {process.env.REACT_APP_TITLE}</CompositeDialogTitle>
          <DialogContent>
            <SubmitPayment
              purchaseSecret={purchaseSecret}
              purchaseStatus={purchaseStatus}
              product={purchaseOffer}
              onError={(err) => {
                setErrorMessage(err);
              }}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={popupMessaage != ""} onClose={() => setPopupMessage("")} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {process.env.REACT_APP_TITLE}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {popupMessaage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPopupMessage("")} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
        
      </div>
  );
}
