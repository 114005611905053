import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'

import {Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import moment from 'moment'

import * as Products from './Products.js';
import { padding } from "@mui/system";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

export function getProductList(offers) {
  if (!offers || offers.length == 0) {
    return []
  }

  const locale = navigator.language
  if(!locale) locale = ""
  
  const products = new Map();

  // create a list of products
  for (let index = 0; index < offers.length; index++) {
    const element = offers[index];
    if(element.locales) continue
    products.set(element.productId,element)
  }

  // look at prices that match the locale
  for (let index = 0; index < offers.length; index++) {
    const element = offers[index];
    if(!element.locales) continue
    var locales = element.locales.split(',')
    for (let index = 0; index < locales.length; index++) {
      if(locales[index].toLowerCase() == locale.toLowerCase()) {
        // this is the preferred price
        products.set(element.productId,element)
      }
    }
  }

  // determine the price per day for the offers
  products.forEach((product) => {
    var days = 0
    var durationTitle = ""
    if(!product.duration) {
      days = 1
    }
    else if(product.duration.endsWith("d")) {
      days = product.duration.split("d")[0]
      if(days > 1) {
        durationTitle = days + "Days"
      } else {
        durationTitle = "Daily"
      }
    } else if(product.duration.endsWith("w")) {
      const weeks = product.duration.split("w")[0]
      days = weeks * 7
      if(weeks > 1) {
        durationTitle = weeks + "Weeks"
      } else {
        durationTitle = "Weekly"
      }
    } else if(product.duration.endsWith("m")) {
      const months = product.duration.split("m")[0]
      days = months * 30
      if(months > 1) {
        durationTitle = months + "Months"
      } else {
        durationTitle = "Monthly"
      }
    } else if(product.duration.endsWith("y")) {
      const years = product.duration.split("y")[0]
      days = years * 365
      if(years > 1) {
        durationTitle = years + "Years"
      } else {
        durationTitle = "Annually"
      }
    }
    product.days = days
    product.dailyCost = product.price / days 
    product.durationTitle = durationTitle
    product.premium = product.productCode.endsWith('premium')
  })

  // determine the best product price
  var lowestPrice = 99999999
  var highestPrice = 0
  products.forEach((product) => {
    if (product.dailyCost < lowestPrice) {
      lowestPrice = product.dailyCost
    }
    if (product.dailyCost > lowestPrice) {
      highestPrice = product.dailyCost
    }
  })

  // assign best price
  products.forEach((product) => {
    product.bestPrice = (product.dailyCost == lowestPrice)
    product.discount =  Math.round((1 - (product.dailyCost / highestPrice)) * 100)
    if(product.discount > 0) {
      product.discountTitle = " >> " + product.discount + "% SAVING"
    }
  })

  var sortedProducts = Array.from(products.values())

  sortedProducts.sort((product1, product2) => {
    if(product1.productCode.endsWith('premium') && !product2.productCode.endsWith('premium')) {
      return -1
    }
    if(!product1.productCode.endsWith('premium') && product2.productCode.endsWith('premium')) {
      return 1
    }
    return product1.dailyCost - product2.dailyCost
  })

  return sortedProducts
}

export function getPrice(offer) {

  var formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: offer.currency
  });

  return formatter.format((offer.price / 100))
}

export function getSubscriptionEndDate(profile) {
  //if (typeof myVar !== 'undefined')
  var epoch;
  if(profile.trialEndDate) {
    epoch = moment(profile.trialEndDate)
  }
  if(profile.subscriptionEndDate) {      
    if(!epoch || (epoch && moment(profile.subscriptionEndDate).isAfter(epoch))) {
      epoch = moment(profile.subscriptionEndDate)
    }
  } 
  
  return epoch.format("MMMM Do YYYY, h:mm:ss a");
}

export function isNewUser(profile) {
  return !profile.subscriptionEndDate && !profile.trialEndDate;
}

export function getSubsciprionMessage(profile) {
  var message = ""
  var epoch
  if(profile.trialEndDate) {
    message = "Trial "
    epoch = moment(profile.trialEndDate)
  }
  if(profile.subscriptionEndDate) {      
    if(!epoch || (epoch && moment(profile.subscriptionEndDate).isAfter(epoch))) {
      message = "Subscription "
      epoch = moment(profile.subscriptionEndDate)
    }
  } 
  
  if (epoch) { 
    if(epoch.isBefore(moment.now())) {
      message += "expired"
    } else {
      message += "expires"
    }
  }
  return message + ":"
}

export function hasSubsciprionExpired(profile) {
  var epoch;
  if(profile.trialEndDate) {
    epoch = moment(profile.trialEndDate)
  }
  if(profile.subscriptionEndDate) {      
    if(!epoch || (epoch && moment(profile.subscriptionEndDate).isAfter(epoch))) {
      epoch = moment(profile.subscriptionEndDate)
    }
  } 
  if(!epoch) {
    return true
  }
  return epoch.isBefore(moment.now())
}

export const SubscriptionBullet = (props) => {
  var { benefit, ...rest } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <img 
        style={{ width: "16px", margin: '0px 10px 0px 0px' }}
        src={process.env.REACT_APP_CHROME + '/check.png'}
        alt={process.env.REACT_APP_CHROME}
      />
      <Typography variant="subtitle1" component="div">
        {benefit}
      </Typography>
    </Grid>
  );
};

export const SubscriptionItem = (props) => {
  /*var { title, content, ...rest } = props; */
  var { product, ...rest } = props;
  return (
    <Grid
      item
      key={product.name}
      xs={12}
      sm={product.premium ? 12 : 6}
      md={3}
      {...rest}>    
      <Paper style={{ height: "100%", padding: '20px 20px 20px 20px' }}>
        <Typography gutterBottom variant="subtitle1" component="div">
          {product.name}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {getPrice(product)}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {"Renews " + product.durationTitle}
        </Typography>
        <Button variant={product.premium ? "contained" : "outlined"}>Choose Plan</Button>
        <Box style={{ padding: '20px 0px 0px 0px' }}>
          <SubscriptionBullet benefit="Feature 1"/>
          <SubscriptionBullet benefit="Feature 2"/>
          <SubscriptionBullet benefit="Feature 3"/>
        </Box>
        {props.children}
      </Paper>
    </Grid>
  );
};

export const AvailableSubscriptions = (props) => {
  var { purchaseSubscription, ...rest } = props;
  const products = getProductList(Products.list())
  return (
    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
      { Array.from(products, (offer) => {
        return(
          <SubscriptionItem 
            product={offer}
            key={offer.priceId}
            onClick={() => {
              purchaseSubscription(offer)
            }}
          />
        )
      }) }
    </Grid>
  );
};

export const SubmitPayment = (props) => {
  const { purchaseSecret, onError, purchaseStatus, product, ...other } = props;

  const [ready, setReady] = useState(false);

  return (
    <Box>
      {purchaseSecret &&
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: ready ? '' : '0px', visibility: ready ? "visible" : "hidden"}}
        >
            <Grid item sm={12} md={12}>
            <Elements stripe={stripePromise} options={{'clientSecret': purchaseSecret}} >
              <CheckoutForm 
                onError={(err) => {
                  onError(err);
                }}
                onReady={(status) => {
                  setReady(true)
                }}
                isSetup={purchaseStatus}
                product={product}
              />
            </Elements>
          </Grid>
        </Grid>    
     }                 
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: !ready ? '400px' : '0px', visibility: !ready ? "visible" : "hidden"}}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>    

    </Box>
  )

}

export const CheckoutForm = (props) => {

  const { onError, isSetup, onReady, product, ...other } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [ready, setReady] = useState(false);

  const handleReady = (ready) => {
    setReady(ready)
    onReady(ready)
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    var result

    if(isSetup) {
      result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
    } 
    else {
      result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
    }

    

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
      onError(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement 
        onReady={() => handleReady(true)}
      />
      {(elements && ready) && 
        <Box style={{ padding: '20px 0px 0px 0px' }}>
          <Button 
            type="submit"
            variant="contained"
            disabled={!stripe}   
            fullWidth               
          >
            Pay Now - {getPrice(product) + " / " + product.durationTitle}
          </Button>
        </Box>
      }
    </form>
  )
};