import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import LoginPage from './LoginPage.js'
import { AccountSettings } from "./AccountSettings.js";
import { PublicPage } from "./PublicPage.js";
import { PasswordResetPage } from './PasswordResetPage.js'
import { useAuth, ProvideAuth } from "./Auth.js";
import * as Constants from './Constants.js';

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_THEME_COLOR,
    },
    info: {
      main: '#00615F',
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_THEME_COLOR,
    },
  },
});

export default function App() {

  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <div>
              <Switch>
                <Route path={Constants.ROUTES.Login}>
                  <LoginPage/>
                </Route>
                <Route path={Constants.ROUTES.Register}>
                  <LoginPage mode="resgistration"/>
                </Route>
                <Route path={Constants.ROUTES.ResetPassword}>
                  <PasswordResetPage/>
                </Route>
                <PrivateRoute path={Constants.ROUTES.Home}>
                  <AccountSettings />
                </PrivateRoute>                
                <Route path="/">
                  {/* <PublicPage /> */}
                  <Redirect
                    to={{
                      pathname: Constants.ROUTES.Login
                    }}
                  />
                </Route>                
              </Switch>
            </div>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </ProvideAuth>
  );
}

export function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  return auth.user ? (
    <p>
      {auth.user.username + "  "}
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<ExitToAppIcon />}
        onClick={() => {
          auth.signout(() => history.push("/"));
        }}
      >
        Exit
      </Button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isPotentiallySignedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
